import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Toggler, Heading } from '../../../../components'
import { StoreState } from '../../../../state/create-store'
import { resumeActions } from '../../../../state/actions'

interface WelcomePreviewProps extends React.HTMLAttributes<HTMLDivElement> { }

export const WelcomePreview: React.FC<WelcomePreviewProps> = (props) => {
  const dispatch = useDispatch()
  const store = useSelector((state: StoreState) => {
    return state.resume
  })

  const stoked = store.values.welcome.intro

  function handleToggle(index: number) {
    dispatch(resumeActions.updateValue({
      phase: 'welcome',
      property: 'togglerIntro',
      value: index,
    }))
  }

  return (
    <div id={'phase__' + props.id} className={props.className}>
      {stoked &&
        <div>
          <Heading size={3} margin={[null, null, 3]}>
            I am <strong className="c--jazzy example-result">{stoked}</strong>&nbsp;
            pumped to get started!
          </Heading>
          <Heading size={5}>
            This has been a <Toggler id="exampleToggle" options={['wonderful', 'tedious']} className="example-toggle" onUpdate={handleToggle} /> start!
          </Heading>
        </div>
      }
    </div>
  )
}
