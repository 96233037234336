import React from 'react'
import { ResumeFormStep } from './resume-form-step'
import { SchemaStep } from '../schema'

const welcomeSchema: SchemaStep = {
  id: 'intro',
  phase: 'welcome',
  intro: 'Welcome',
  question: 'How pumped are you to begin?',
  imageUrl: 'https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/editor-intro.png',
  type: 'radio-group',
  options: [
    { label: 'Totally', value: 'totally' },
    { label: 'Completely', value: 'completely' },
    { label: '110%', value: 'impossibly' },
    { label: 'Meh', value: 'lying about being' },
  ]
}

export const ResumeFormWelcome: React.FC = () => {
  return (
    <ResumeFormStep
      key="welcome"
      index={-1}
      {...welcomeSchema}
    />
  )
}
