import React, { useState } from 'react'
import axios, { CancelToken } from 'axios'
import { motion } from 'framer-motion'

import { StoreState } from '../../state/create-store'

interface ResumePortraitProps extends React.HTMLAttributes<HTMLDivElement> {
  portrait: StoreState['resume']['values']['portrait']
}

enum PortraitStatus {
  LOADING = 'loading',
  COMPLETE = 'complete',
}

const TRANSITION_DURATION = 250
const MakeToken = axios.CancelToken

export const ResumePortrait: React.FC<ResumePortraitProps> = (props) => {
  const [portraitUrls, setPortraitUrls] = useState<Record<string, string>>({})
  const [swapUrls, setSwapUrls] = React.useState<Record<string, string>>(null)
  const [status, setStatus] = React.useState(PortraitStatus.LOADING)
  const [cancelToken, setCancelToken] = useState(MakeToken.source())
  const { id, portrait, ...passProps } = props

  React.useEffect(() => {
    setStatus(PortraitStatus.LOADING)
    cancelToken.cancel()
    const newCancelToken = MakeToken.source()
    setCancelToken(newCancelToken)

    getPortaitUrls(newCancelToken.token)
  }, [props.portrait])

  React.useEffect(() => {
    setStatus(PortraitStatus.LOADING)
  }, [portraitUrls.md])

  async function getPortaitUrls(cancelToken: CancelToken) {
    // strip out empty values
    const portraitParams = Object.keys(props.portrait).reduce((acc, cur) => {
      if (props.portrait[cur]) {
        acc[cur] = props.portrait[cur]
      }
      return acc
    }, {} as Record<string, string>)

    try {
      const { data } = await axios.get<Record<string, string>>(
        '/api/image',
        {
          params: portraitParams,
          cancelToken: cancelToken
        }
      )
      setPortraitUrls(data)
    } catch (ignore) { }
  }


  function afterLoad(e: React.SyntheticEvent) {
    setStatus(PortraitStatus.COMPLETE)
  }

  function afterAnimation() {
    setSwapUrls(portraitUrls)
  }

  return (
    <div id="portrait" {...passProps}>
      <motion.picture
        className="portrait__image"
        onLoad={afterLoad}
        style={{ zIndex: 2 }}
        animate={{ opacity: status === PortraitStatus.COMPLETE ? 1 : 0 }}
        transition={{ duration: TRANSITION_DURATION / 1000 }}
        onAnimationComplete={afterAnimation}
        initial={false}
      >
        <source srcSet={`${portraitUrls.xs} 576w`} media="(max-width: 720px)" />
        <source srcSet={`${portraitUrls.md} 1200w, ${portraitUrls.lg} 2400w`} />
        <img src={portraitUrls.md} />
      </motion.picture>

      {swapUrls &&
        <picture className="portrait__image">
          <source srcSet={`${swapUrls.xs} 576w`} media="(max-width: 720px)" />
          <source srcSet={`${swapUrls.md} 1200w, ${swapUrls.lg} 2400w`} />
          <img src={swapUrls.md} />
        </picture>
      }

      {status === PortraitStatus.LOADING && <div className="loader" />}
    </div>
  )
}
