import React from 'react'
import cn from 'classnames'

import { StoreState } from '../../state/create-store'
import { BaseProps } from '../../types'
import { Heading } from '../../components'
import { marginClass } from '../../library/spacing'

interface PastProps extends BaseProps {
  layout: StoreState['resume']['values']['experience']['past']
}

interface Job {
  date: string
  location: string
  title: string
  description: string
}

const experience: Job[] = [
  {
    date: '2017–2018',
    location: 'Well Done',
    title: 'Freelance Design.',
    description: 'Worked varies clients and partnered with local agencies on graphic design and web developemt projects.',
  },
  {
    date: '2014–2017',
    location: 'SRM Architecture and Marketing',
    title: 'Upgrade to Lead Digital Designer.',
    description: 'On all digital projects I took on the lead design role and was more active with creative direction, project management and client communication responsibilities. Projects were typically websites and interactive displays.',
  },
  {
    date: '2012–2014',
    location: 'SRM Architecture and Marketing',
    title: 'Graphic Designer / Developer at a multidisciplinary design firm.',
    description: 'Worked with a great team of graphic designers, interior designers, and architects on projects big and small. Being a part of such a diverse firm allowed me to to work on a large range of projects — including websites, print materials, brand experiences, interior graphics, and environmental signage.',
  },
  {
    date: '2009–2012',
    location: 'Associated Students Publicity Center',
    title: 'Part time Graphic Designer.',
    description: 'While going to university I worked part-time at the school run graphic design office. I worked mainly with campus clubs and organizations to produce promotional materials in various formats — including posters, handbills, digital signage, t-shirts, logos, and banners.',
  },
  {
    date: '2008–2012',
    location: 'Western Washington University',
    title: 'Grabbing a Bacholers of Arts degree in Graphic Design.',
    description: 'The graphic design program at WWU not only taught me the traditional design process, but provided me with a technical foundation for producing designs for the real world. My senior year focus was in print production.',
  },

]

export const ResumePast: React.FunctionComponent<PastProps> = (props) => {

  function renderJob(job: Job) {
    return (
      <div className="job" key={job.title}>
        <div className={cn('f--1', marginClass([null, null, 1]))}>
          <span className="job__date">{job.date}</span>
          <span className="job__location">{job.location}</span>
        </div>
        <Heading className="job__title">{job.title}</Heading>
        <p className="job__desc">{job.description}</p>
      </div>
    )
  }

  return (
    <div className={props.className}>
      {
        props.layout === 'detail' &&
        <div>
          {experience.map(job => renderJob(job))}
        </div>
      }
      {
        props.layout === 'nutshell' &&
        <Heading size={5}>
          Previously I was a freelance designer / developer, after working at {' '}
          <u>SRM Architecture and Marketing</u> as a graphic designer & developer
          on a wide variety of projects. Before that I was at <u>Western Washington
          University</u> grabbing a BA in Graphic Design.
        </Heading>
      }
    </div>
  )
}
