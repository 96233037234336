import React, { useRef, useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import { resumeActions } from '../../../state/actions'
import { StoreState } from '../../../state/create-store'
import { Dialog, Buttons, Button, Heading, Tour } from '../../../components'
import { TourStepOptions } from '../../../components/tour/src/tour-step'
import { paddingClass } from '../../../library/spacing'

export const ResumeEditorTour: React.FC = () => {
  const tourRef = useRef<Tour>()
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [hasMadeSelection, setHasMadeSelection] = useState(false)
  const values = useSelector((state: StoreState) => {
    return state.resume.values.welcome
  })
  const status = useSelector((state: StoreState) => {
    return state.resume.status
  })


  const tourSteps: TourStepOptions[] = [
    {
      id: 'editor',
      heading: 'Editor Pane',
      text: 'This is where you will make your selections.',
      attachTo: '.editor__step .editor__question',
      position: 'top',
      beforeShow: () => {
        dispatch(resumeActions.showPreview(false))
      }
    },
    {
      id: 'editor-selection',
      text: <span>Go ahead and <strong>pick an option</strong></span>,
      attachTo: '.editor__step .editor__content',
      position: 'bottom',
      showNavigation: false,
      enableTargetInteraction: true,
    },
    {
      id: 'editor-response',
      heading: 'Nice pick!',
      attachTo: '.editor__step .radio-group .-selected',
      position: 'top',
      showNavigation: false,
      showClose: false,
      beforeShow: () => {
        window.setTimeout(() => {
          tourRef.current?.nextStep()
        }, 1200)
      }
    },
    {
      id: 'preview',
      heading: 'Preview Area',
      text: 'Here you can preview the results of your selections and (sometimes) make further edits.',
      attachTo: '.preview__phase .example-result',
      position: 'top',
      delay: 950,
      beforeShow: () => {
        dispatch(resumeActions.showPreview(true))
      },
    },
    {
      id: 'toggler',
      heading: 'Perfect wording',
      text: <span>Underlined areas allow you to toggle through wording options. Try it out by <strong>clicking on the underline below</strong>.</span>,
      attachTo: '.example-toggle',
      enableTargetInteraction: true,
      showNavigation: false,
    },
    {
      id: 'toggler-response',
      heading: 'Looking good!',
      attachTo: '.example-toggle',
      showNavigation: false,
      showClose: false,
      onShow: () => {
        window.setTimeout(() => {
          tourRef.current?.nextStep()
        }, 1800)
      }
    },
    {
      id: 'navigation',
      heading: 'Navigation',
      text: <span>This is how you move about. Go ahead and <strong>click to the next step</strong>.</span>,
      attachTo: '.editor__nav',
      showNavigation: false,
      enableTargetInteraction: true,
      delay: 650,
      beforeShow: () => {
        dispatch(resumeActions.showPreview(false))
        document.querySelector('.editor__nav').addEventListener('click', () => {
          tourRef.current?.nextStep()
        }, { once: true })
      },
    },
    {
      id: 'progress',
      heading: 'Progress',
      text: 'This area shows your progress. You can also jump to different sections here (once completed).',
      attachTo: '.editor__phase-dots',
    },
    {
      id: 'close',
      heading: 'Regrets?',
      text: 'If you ever want to throw in the towel, you can use this to skip to a finished resume.',
      attachTo: '.close-btn',
    },
    {
      id: 'finish',
      heading: 'That\'s it! You are ready to tackle it on your own',
      text: 'Don\'t worry, it won\'t take long.',
      onClose: () => {
        dispatch(resumeActions.completeTour())
      }
    },
  ]

  useEffect(() => {
    if (tourRef.current) {
      if (!status.isTourComplete) {
        setDialogOpen(true)
      }
    }
  }, [])

  useEffect(() => {
    if (values && values.intro !== '' && !hasMadeSelection) {
      setHasMadeSelection(true)
      tourRef.current?.nextStep()
    }
    if (values?.togglerIntro === 1) {
      tourRef.current?.nextStep()
    }
  }, [values])

  function startTour() {
    setDialogOpen(false)
    window.setTimeout(() => {
      tourRef.current?.start()
    }, 600)
  }

  function cancel() {
    dispatch(resumeActions.completeTour())
    dispatch(resumeActions.completeStep({ index: -1 }))
    dispatch(resumeActions.setPhase({ phase: 0, step: 0 }))
    setDialogOpen(false)
  }

  return (
    <Fragment>
      <Tour ref={tourRef} steps={tourSteps} onClose={cancel} />
      <Dialog
        isOpen={dialogOpen}
        size="sm"
        onClose={cancel}
        isClosedOnOutsideClick={false}
      >
        <Dialog.Body className={cn('t--center', paddingClass([6]))}>
          <Heading size={5} className="c--jazzy">Welcome</Heading>
          <p>Thank you for taking the time to create the perfect resume.</p>
          <h5>Let's just take a quick tour!</h5>
        </Dialog.Body>
        <Dialog.Footer>
          <Buttons justify="center">
            <Button intent="secondary" appearance="outline" label="Veto" onClick={cancel} />
            <Button intent="primary" label="*sigh* Alright" onClick={startTour} />
          </Buttons>
        </Dialog.Footer>
      </Dialog>
    </Fragment>
  )
}
