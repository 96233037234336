import React from 'react'
import { useSelector } from 'react-redux'

import { StoreState } from '../../../../state/create-store'
import { ResumePortrait } from '../../resume-portrait'

interface PortraitPreviewProps extends React.HTMLAttributes<HTMLDivElement> { }

export const PortraitPreview: React.FC<PortraitPreviewProps> = (props) => {
  const portrait = useSelector((state: StoreState) => {
    return state.resume.values.portrait
  });

  return (
    <div id="phase__portrait">
      <ResumePortrait portrait={portrait} />
    </div>
  )
}
