import React from 'react'
import { useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'


import { StoreState } from '../../state/create-store'
import { Layout } from '../../templates'
import { SEO } from '../../partials'
import { ResumeLayout, ResumeIntro } from '../../partials/resume'
import { ResumeEditor } from '../../partials/resume/editor/resume-editor'

const ResumePage: React.FC = () => {
  const status = useSelector((state: StoreState) => {
    return state.resume.status
  })
  const values = useSelector((state: StoreState) => {
    return state.resume.values
  })

  return (
    <Layout>
      <SEO title="Resume | Adam Volkman" />
      <AnimatePresence>
        {status.showIntro && <ResumeIntro key="intro" />}
        {status.editMode && <ResumeEditor key="editor" />}
        {!status.showIntro && !status.editMode && status.isComplete &&
          <ResumeLayout key="layout" values={values} />
        }
      </AnimatePresence>
    </Layout >
  )
}

export default ResumePage
