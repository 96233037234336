import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'

import { StoreState } from '../../state/create-store'
import { alert, Icon, Tooltip } from '../../components'
import { resumeActions } from '../../state/actions'

export const ResumeMenu = () => {
  const dispatch = useDispatch()

  const isComplete = useSelector((state: StoreState) => {
    return state.resume.status.isComplete
  })

  function resetResume() {
    dispatch(resumeActions.reset())
    navigate('/resume', { replace: true })
  }

  function handleReset() {
    alert.show({
      title: 'Are you sure?',
      body: 'Just checking, because this will erase all the things.',
      onResolve: resetResume,
      confirmText: 'Yes',
      cancelText: 'Nevermind',
    })
  }

  function handleEdit() {
    dispatch(resumeActions.setPhase({ phase: 'toc', step: -1 }))
    dispatch(resumeActions.editMode({ isActive: true }))
    if (!isComplete) {
      dispatch(resumeActions.setDefault())
    }
    if (window.location.pathname !== '/resume') {
      navigate('/resume', { replace: true })
    }
  }

  return (
    <div className="resume-menu">
      <div className="resume-menu__inner">
        <Tooltip
          position="right"
          trigger="hoverTarget"
          hoverOpenDelay={250}
        >
          <div onClick={handleEdit} className="sidebar-btn">
            <Icon name="Edit" /><span className="hidden-sm-up">edit</span>
          </div>
          <span>Edit</span>
        </Tooltip>

        {isComplete &&
          <Tooltip
            position="right"
            trigger="hoverTarget"
            hoverOpenDelay={250}
          >
            <div onClick={handleReset} className="sidebar-btn">
              <Icon name="Trash" />
              <span className="hidden-sm-up">restart</span>
            </div>
            <span>Start Over</span>
          </Tooltip>
        }
      </div >
    </div>
  )
}
