import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'

import { StoreState } from '../../../state/create-store'
import { resumeActions } from '../../../state/actions'
import {
  WelcomePreview,
  IntroPreview,
  InterestsPreview,
  AboutPreview,
  PortraitPreview,
  SkillsPreview,
  PastPreview
} from './previews'

export const ResumeEditorPreview: React.FC = () => {
  //
  // Constants
  //
  const dispatch = useDispatch()
  const status = useSelector((state: StoreState) => {
    return state.resume.status
  })

  const values = useSelector((state: StoreState) => {
    return state.resume.values
  })


  //
  // Actions
  //

  function openPreview() {
    dispatch(resumeActions.showPreview(true))
  }

  //
  // Render
  //

  function renderPreview() {
    const { currentPhase, currentStep } = status

    if (currentPhase === 'welcome' && values.welcome.intro) {
      return <PreviewStep key="welcome"><WelcomePreview /></PreviewStep>
    } else if (currentPhase == 0 && currentStep == 1) {
      return <PreviewStep key="intro"><IntroPreview /></PreviewStep>
    } else if (currentPhase == 0 && currentStep == 2) {
      return <PreviewStep key="interests"><InterestsPreview /></PreviewStep>
    } else if (currentPhase == 0 && currentStep == 3) {
      return <PreviewStep key="about"><AboutPreview /></PreviewStep>
    } else if (currentPhase == 1) {
      return <PreviewStep key="portrait" ><PortraitPreview /></PreviewStep>
    } else if (currentStep == 11) {
      return <PreviewStep key="skills"><SkillsPreview /></PreviewStep>
    } else if (currentStep == 12 && values.experience.past) {
      return <PreviewStep key="past"><PastPreview /></PreviewStep>
    } else {
      return null
    }
  }

  return (
    <motion.div
      onMouseDown={openPreview}
      className="resume-editor__content"
      onClick={openPreview}
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      exit={{ x: '100%' }}
      transition={{ damping: 300, stiffness: 150 }}
    >
      <div className="content__preview">
        <AnimatePresence exitBeforeEnter>
          {renderPreview()}
        </AnimatePresence>
      </div>
    </motion.div>
  )
}

const PreviewStep: React.FC = (props) => {
  const direction = useSelector((state: StoreState) => {
    return state.resume.status.editorDirection
  })

  const variants = {
    enter: (direction: number) => {
      return {
        opacity: 0,
        y: direction > 0 ? '10%' : '-10%',
      }
    },
    active: {
      opacity: 1,
      y: 0,
      zIndex: 1,
      transition: {
        y: { type: "spring", stiffness: 100, damping: 300 },
        opacity: { duration: 0.25 },
      }
    },
    exit: (direction: number) => {
      return {
        opacity: 0,
        y: direction > 0 ? '-10%' : '10%',
        transition: {
          y: { type: "spring", stiffness: 200, damping: 300 },
          opacity: { duration: 0.25 },
        }
      }
    },
  }

  return (
    <motion.div
      className="preview__phase"
      custom={direction}
      variants={variants}
      initial="enter"
      animate="active"
      exit="exit"
    >
      {props.children}
    </motion.div>

  )
}
