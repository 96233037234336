import React from 'react'
import { SchemaStep } from '../schema'
import cn from 'classnames'

export interface RadioGroupProps {
  options: SchemaStep['options']
  value: string
  onUpdate?: (values: string) => void
}

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = (props) => {

  function setValue(value: string) {
    if (typeof props.onUpdate == 'function') {
      props.onUpdate(value)
    }
  }

  function isActive(option: string) {
    return props.value === option
  }

  return (
    <div className={'radio-group'}>
      {props.options.map(option => (
        <div className="radio-option">
          <button
            onClick={() => { setValue(option.value) }}
            className={cn({ '-selected': isActive(option.value) })}
          >
            {option.label}
          </button>
        </div>
      ))}
    </div>
  )
}
