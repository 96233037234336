import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'

import { Toggler, Heading, Tooltip, Icon } from '../../../../components'
import { StoreState } from '../../../../state/create-store'
import { resumeActions } from '../../../../state/actions'


export const IntroPreview: React.FC = () => {
  const dispatch = useDispatch()
  const values = useSelector((state: StoreState) => {
    return state.resume.values.about
  })
  const staticState = useSelector((state: StoreState) => {
    return state.resume.static
  })

  function updateToggle(id: string, value: number) {
    dispatch(resumeActions.updateValue({
      phase: 'about',
      property: id,
      value
    }))
  }

  //
  // Render
  //

  function renderIntro() {
    switch (values.introStyle) {
      case 'poetic':
        return (
          <div>
            <Heading size={5}>
              A maker of things.<br />
              Design, dev, &amp; draw – you bet!<br />
              A poet?&nbsp;
              <Toggler
                id="togglerPoem"
                index={values.togglerPoem}
                options={staticState.togglerPoem.options}
                onUpdate={(index) => updateToggle('togglerPoem', index)}
              />.
              <Tooltip>
                <sup className="c--gray-light"><Icon name="InfoFill" /></sup>
                <span><b>Hint.</b> It's a haiku</span>
              </Tooltip>
            </Heading>
            <Heading
              size={1}
              family="mono"
              margin={[4, null, null]}
              className={cn("c--gray-light")}
            >
              — Adam Volkman, 2017
            </Heading>
          </div>
        )
      case 'minimal':
        return (
          <div>
            <h3>Adam Volkman:</h3>
            <Heading size={6}>A&nbsp;
              <Toggler
                id="togglerMinimal"
                index={values.togglerMinimal}
                options={staticState.togglerMinimal.options}
                onUpdate={(index) => updateToggle('togglerMinimal', index)}
              />.
            </Heading>
          </div>
        )
      case 'confident':
        return (
          <div>
            <Heading size={5}>
              A pretty slick dude with sweet skills, a good attitude, and&nbsp;
              <Toggler
                id="togglerConfident"
                index={values.togglerConfident}
                options={staticState.togglerConfident.options}
                onUpdate={(index) => updateToggle('togglerConfident', index)}
              />.&nbsp;
              A true triple threat.
            </Heading>
          </div>
        )
      case 'hard':
        return (
          <div>
            <Heading size={5}>
              I'm a graphic designer and developer currently doing my own thing.
              It's pretty sweet. I mean, I could maybe help you out if you need me.
            </Heading>
          </div>
        )
      case 'elevator':
        return (
          <div key="elevator">
            <Heading size={3} margin={[null, null, 2]} className="c--gray">
              Okay, picture this —
            </Heading>
            <Heading size={5}>It's like&nbsp;
              <Toggler
                id="togglerElevator"
                index={values.togglerElevator}
                options={staticState.togglerElevator.options}
                onUpdate={(index) => updateToggle('togglerElevator', index)}
              />.
            </Heading>
          </div>
        )
      case 'standard':
        return (
          <div>
            <Heading size={5}>
              A graphic designer, web developer, and illustrator using all the tools at my disposal to &nbsp;
              <Toggler
                id="togglerIntro"
                index={values.togglerIntro}
                options={staticState.togglerIntro.options}
                onUpdate={(index) => updateToggle('togglerIntro', index)}
              />.
            </Heading>
          </div>
        )
      default:
        return (
          <div className="message -info -max--sm -center">
            <p className="c--jazzy">
              <strong>Remember! —</strong>
              The first impression is very important. It sets the tone for the whole
              resume and you'll decide instantly whether you hate me or not.
            </p>
          </div>
        )
    }
  }

  return (
    <div id="phase__intro">
      <div>
        {renderIntro()}
      </div>
    </div>
  )
}
