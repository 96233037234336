import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { Column, Columns, Heading, Card } from '../../../../components'
import { StoreState } from '../../../../state/create-store'
import { marginClass } from '../../../../library/spacing'

const skillList = {
  print: [
    'Print Design & Production',
    'Environmental Graphics',
    'Signage & Wayfinding',
    'Screen Printing',
    'Letterpress Printing',
  ],
  digital: [
    'Branding / Logos',
    'Web / Digital Design',
    'Iconography',
    'Illustration',
    'Photo Editing',
  ],
  development: [
    'HTML, LESS, SCSS, Javascript, PHP',
    'Bootstrap, UIkit',
    'Laravel, CraftCMS, Wordpress',
    'Composer, NPM',
    'Vagrant',
  ],
  other: [
    'Baked goods',
    'Book binding',
    'Unicycling',
    'Spoon carving',
    'Entry level Ukelele skills',
  ]
}

export const SkillsPreview: React.FunctionComponent = () => {
  const skills = useSelector((store: StoreState) => {
    return store.resume.values.experience.skills
  })

  function renderSkillList(title: string, items: string[]) {
    return (
      <Card>
        <Card.Block>
          <Heading size={3} margin={[null, null, 3]}>{title} —</Heading>
          <ul className="-relaxed">
            {
              items.map((i, index) => (
                <li key={index}>{i}</li>
              ))
            }
          </ul>
        </Card.Block>
      </Card>
    )
  }

  function getTitle(skill: string) {
    switch (skill) {
      case 'print':
        return 'ink'
      case 'development':
        return 'bytes'
      case 'digital':
        return 'pixels'
      default:
        return skill
    }
  }

  return (
    <div id="phase__skills">
      <Columns className="mt-3">
        <Column>
          {skills.length > 0 &&
            <Columns>
              {skills.map(skill => {
                if (skillList.hasOwnProperty(skill)) {

                  return (
                    <Column key={skill} span={6} className={marginClass([null, null, 6])}>
                      {renderSkillList(getTitle(skill), skillList[skill])}
                    </Column>
                  )
                }
                return null
              })}
            </Columns>
          }
          {skills.length === 0 &&
            <div className={cn('message', '-info', 'c--jazzy', '-max--md', '-center')}>
              <strong>Pro Tip! —</strong> Even if you don't need a skill now, it may come in handy in the future... you never know.
            </div>
          }
        </Column>
      </Columns>
    </div>
  )
}
