import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { Column, Columns } from '../../../../components'
import { StoreState } from '../../../../state/create-store'
import { marginClass } from '../../../../library/spacing'

export const AboutPreview: React.FunctionComponent = () => {
  const topics = useSelector((store: StoreState) => {
    return store.resume.values.about.manifesto
  })

  function renderTopic(topic: string) {
    let text = ''
    switch (topic) {
      case 'craft':
        text = 'Quality of craft.'
        break
      case 'care':
        text = 'Care about what you do.'
        break
      case 'purpose':
        text = 'Everything needs a purpose.'
        break
      case 'beyond':
        text = 'Go above and beyond.'
        break
      case 'grow':
        text = 'Seek opportunities for growth.'
        break
      case 'climb':
        text = 'Continual improvement.'
        break
      case 'balance':
        text = 'Find the right balance.'
        break
      case 'enjoy':
        text = 'Have a good time.'
        break
    }

    return (
      <span className="c--late-night">{text}</span>
    )
  }

  return (
    <div id="phase__interests">
      <Columns className={cn(marginClass([4, null, null]))}>
        <Column>
          <ol>
            {topics.map(topic => (
              <li
                key={topic}
                className={cn(
                  'heading',
                  'f--5',
                  '-checked',
                  marginClass([null, null, 3])
                )}
              >
                {renderTopic(topic)}
              </li>
            ))}
          </ol>
        </Column>
      </Columns>
    </div>
  )
}
