import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import { StoreState } from '../../../state/create-store'
import { resumeActions } from '../../../state/actions'
import { Button, Icon, alert } from '../../../components'
import { ResumeEditorForm } from './resume-editor-form'
import { ResumeEditorPreview } from './resume-editor-preview'
import { ResumeEditorTour } from './resume-editor-tour'


export interface ResumeEditorProps {
  children?: never
}


export const ResumeEditor: React.FunctionComponent<ResumeEditorProps> = (props) => {
  const dispatch = useDispatch()
  const status = useSelector((state: StoreState) => {
    return state.resume.status
  })

  useEffect(() => {
    let timeout: number

    if (!status.editMode) {
      timeout = window.setTimeout(() => {
        resumeActions.setPhase({ phase: 'toc', step: 0 })
      }, 850)
    }

    return () => {
      window.clearTimeout(timeout)
    }

  }, [status.editMode])

  function togglePreview(): void {
    dispatch(resumeActions.showPreview(!status.showPreview))
  }
  function finishResume(): void {
    dispatch(resumeActions.finishResume())
  }

  function handleClose(): void {
    if (!status.editMode) return
    if (!status.isComplete) {
      alert.show({
        title: 'But, you aren\'t finished yet...',
        body: 'I\'ll have to finish the rest of the resume for you',
        cancelText: 'Fine, I\'ll do it',
        confirmText: 'Yeah, I don\'t care',
        onResolve: finishResume,
      })
    } else {
      dispatch(resumeActions.editMode({ isActive: false }))
    }
  }

  return (
    <div
      className={cn(
        "resume-editor",
        {
          'preview-mode': status.showPreview,
          'edit-mode': !status.showPreview
        }
      )}
    >
      <div className="preview-toggle">
        <div className="preview-toggle__btn" onClick={togglePreview}>
          <Icon name="ArrowRightSm" />
        </div>
      </div>

      <ResumeEditorForm key="form" />
      <ResumeEditorPreview key="preview" />

      <ResumeEditorTour />

      {status.editMode &&
        <Button
          className="close-btn -rounded"
          appearance="outline"
          size="small"
          color="info"
          onClick={handleClose}
          children="close"
        />
      }
    </div>
  )
}

