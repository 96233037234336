import React from 'react'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { useThunkDispatch, StoreState } from '../../../state/create-store'
import { resumeActions } from '../../../state/actions'
import { SchemaStep } from '../schema'
import { CheckboxGroup } from '../inputs/checkbox-group'
import { RadioGroup } from '../inputs/radio-group'
import { SliderInput, Heading, Select } from '../../../components'
import { isFalsy } from '../../../library'
import { marginClass } from '../../../library/spacing'

export interface ResumeFormStepProps {
  imageUrl?: string
  error?: string
  intro?: string
  question?: string
  desc?: string
  type: SchemaStep["type"]
  id: string
  options?: SchemaStep["options"]
  placeholder?: SchemaStep["placeholder"]
  label?: SchemaStep["label"]
  phase?: keyof StoreState['resume']['values']
  index: number
}

export const ResumeFormStep: React.FunctionComponent<ResumeFormStepProps> = (props) => {
  const dispatch = useThunkDispatch();
  const currentValue = useSelector((state: StoreState) => {
    return state.resume.values[props.phase] && state.resume.values[props.phase][props.id] !== undefined
      ? state.resume.values[props.phase][props.id]
      : null
  })
  const editorDirection = useSelector((state: StoreState) => {
    return state.resume.status.editorDirection
  })

  React.useEffect(() => {
    if (props.type === "intro" || !isValueEmpty(currentValue)) {
      dispatch(resumeActions.completeStep({ index: props.index }))
    }
  }, [currentValue])


  function isValueEmpty(value: any) {
    if (isFalsy(value)) return true
    if (Array.isArray(value) && value.length === 0) return true

    return false
  }


  //
  // Constants
  //

  const activeOptions = props.options
    ? props.options.filter(option => {
      if (Array.isArray(currentValue)) {
        return currentValue.includes(option.value)
      } else {
        return currentValue === option.value
      }
    })
    : []

  const variants = {
    enter: (direction: number) => {
      return {
        opacity: 0,
        x: direction > 0 ? '50%' : '-50%',
      }
    },
    active: {
      opacity: 1,
      x: 0,
      zIndex: 1,
      transition: {
        delay: .15,
        x: { type: "spring", stiffness: 200, damping: 300 },
        opacity: { duration: 0.25 },
      }
    },
    exit: (direction: number) => {
      return {
        opacity: 0,
        x: direction > 0 ? '-50%' : '50%',
        transition: {
          x: { type: "spring", stiffness: 250, damping: 300 },
          opacity: { duration: 0.25 },
        }
      }
    },
  }

  //
  // Actions
  //

  function handleUpdate(value: any) {
    dispatch(
      resumeActions.updateValue({ phase: props.phase, property: props.id, value })
    )
  }

  function handleSelectUpdate(values: { value: string, label: string }[]) {
    const formattedValues = values.map(val => {
      return val.value;
    });

    if (props.type === 'checkbox-group') {
      handleUpdate(formattedValues)
    } else {
      handleUpdate(formattedValues[0])
    }
  }

  //
  // Render
  //

  function renderInput() {
    if (currentValue === null) return null;

    switch (props.type) {
      case 'checkbox-group':
        return (
          <CheckboxGroup
            options={props.options}
            value={currentValue}
            onUpdate={handleUpdate}
          />
        )
      case 'radio-group':
        return (
          <RadioGroup
            options={props.options}
            value={currentValue}
            onUpdate={handleUpdate}
          />
        )
      case 'slider-input':
        return (
          <SliderInput
            options={props.options}
            value={currentValue}
            onUpdate={handleUpdate}
            id={props.id}
          />
        )
      case 'select':
        return (
          <Select
            field="select"
            options={props.options}
            activeOptions={activeOptions}
            onActiveChange={handleSelectUpdate}
            renderOption="label"
            renderLabel="label"
            optionIdProp="value"
          />
        )
    }
    return null;
  }

  return (
    <motion.div
      className="editor__step"
      custom={editorDirection}
      variants={variants}
      initial="enter"
      animate="active"
      exit="exit"
    >
      <div className="editor__content">
        {props.imageUrl && (
          <div className="intro-photo">
            <img src={props.imageUrl} />
          </div>
        )}

        <div className="editor__question">
          <Heading size={2} family="mono" margin={[null, null, 2]} className="c--gummy">{props.intro}</Heading>
          <Heading size={4} className="c--late-night">{props.question}</Heading>
          {props.desc &&
            <p className={cn("c--gray", marginClass([4, null, null]))}>
              {props.desc}
            </p>
          }
        </div>

        {props.type !== "intro" && (
          <React.Fragment>
            {/* Mobile Version */}
            <div className="editor__input -condensed">
              <Select
                field="mobile-input"
                options={props.options}
                onActiveChange={handleSelectUpdate}
                activeOptions={activeOptions}
                placeholder={props.placeholder || 'Select'}
                allowMultiple={props.type === 'checkbox-group'}
                isClearable={false}
                renderLabel="label"
                renderOption="label"
                optionIdProp="value"
              />
            </div>
            <div className="editor__input -standard">{renderInput()}</div>
          </React.Fragment>
        )}

        <div className="editor__errors">
          <p className="has-error">{props.error}</p>
        </div>

        {props.children}
      </div>
    </motion.div>
  )
}
