import React from 'react'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { StoreState } from '../../state/create-store'
import { paddingClass, marginClass } from '../../library/spacing'
import { ResumePortrait } from './resume-portrait'
import { ResumeMenu } from './resume-menu'
import { ResumePast } from './resume-past'
import {
  Container,
  Columns,
  Column,
  AnchorButton,
  Heading,
  Card,
  Icon,
  FadeIn,
} from '../../components'


export interface ResumeLayoutProps {
  values: StoreState['resume']['values']
  children?: never
}

export const ResumeLayout: React.FC<ResumeLayoutProps> = (props) => {
  const togglers = useSelector((state: StoreState) => {
    return state.resume.static
  })

  return (
    <motion.div
      className="resume__layout"
      key="resume-layout"
      exit={{ opacity: 0, transition: { delay: 1 } }}
    >
      <div className="resume__layout-wrap menu-pad">
        {/* <!-- Intro --> */}
        <Container maxWidth="xl" className={paddingClass([null, null, 10])}>
          <Columns justify="center">
            {/* <!-- Heading --> */}
            <Column span={{ sm: 10, md: 8 }}>
              <FadeIn tagName="div">
                <Heading size={2} family="mono" margin={[null, null, 4]} className="c--jean-jacket">Adam Volkman —</Heading>

                {/* <!-- standard --> */}
                {props.values.about.introStyle === 'standard' &&
                  <div key="standard">
                    <Heading size={5}>
                      A graphic designer, web developer, and illustrator using all
                      the tools at my disposal to {togglers.togglerIntro.options[props.values.about.togglerIntro]}.
                    </Heading>
                  </div>
                }

                {/* <!-- haiku --> */}
                {props.values.about.introStyle === 'poetic' &&
                  <div>
                    <Heading size={5}>
                      A maker of things.<br />
                      Design, dev, &amp; draw – you bet!<br />
                      A poet? {togglers.togglerPoem.options[props.values.about.togglerPoem]}.
                  </Heading>
                  </div>
                }

                {/* <!-- hard to get --> */}
                {props.values.about.introStyle === 'hard' &&
                  <div v-else-if="introStyle == 'hard'">
                    <Heading size={5}>
                      I'm a graphic designer &amp; developer currently doing my own thing.
                      It's pretty sweet. I mean, if you need some help out, I guess I could join you.
                    </Heading>
                  </div>
                }

                {/* <!-- confident --> */}
                {props.values.about.introStyle === 'confident' &&
                  <div>
                    <Heading size={5}>
                      A pretty slick dude with sweet skills, a good attitude,
                      and {togglers.togglerConfident.options[props.values.about.togglerConfident]}.
                      A true triple threat.
                    </Heading>
                  </div>
                }

                {/* <!-- minimal --> */}
                {props.values.about.introStyle === 'minimal' &&
                  <div>
                    <Heading size={6}>
                      A {togglers.togglerMinimal.options[props.values.about.togglerMinimal]}
                    </Heading>
                  </div>
                }

                {/* <!-- Elevator --> */}
                {props.values.about.introStyle === 'elevator' &&
                  <div>
                    <Heading size={5}>
                      Like {togglers.togglerElevator.options[props.values.about.togglerElevator]}.
                    </Heading>
                  </div>
                }

                {/* <!-- intro par --> */}
                <p className={marginClass([8, null, null])}>
                  I'm a multidisciplinary graphic designer who works on all substrates, surfaces, and screens. I also do web development, illustration, and whatever else I can to get the job done. My approach tends to be simple and functional, yet I'm a sucker for hand lettering and illustrations.
                </p>
                <p>
                  Creating is my greatest passion, but to show I'm an interesting person — I also enjoy{" "}
                  <ul className="-inline-text">
                    {/* <!-- cooking --> */}
                    {props.values.about.personal.indexOf('cooking') > -1 && <li key="cook">baking treats</li>}

                    {/* <!-- hiking --> */}
                    {props.values.about.personal.indexOf('hiking') > -1 && <li key="hike">hiking through the mountains</li>}

                    {/* <!-- telivision --> */}
                    {props.values.about.personal.indexOf('television') > -1 && <li key="tv">binging on television</li>}

                    {/* <!-- tennis --> */}
                    {props.values.about.personal.indexOf('tennis') > -1 && <li key="tennis">playing tennis</li>}

                    {/* <!-- crafts --> */}
                    {props.values.about.personal.indexOf('crafts') > -1 && <li key="crafts">getting crafty</li>}

                    {/* <!-- learning --> */}
                    {props.values.about.personal.indexOf('learn') > -1 && <li key="learn">learning new skills</li>}
                  </ul>.
                </p>
              </FadeIn>
            </Column>
          </Columns>
        </Container>

        {/* <!-- Portrait --> */}
        <Container maxWidth="xl" className={cn(paddingClass([6, null]))}>
          <FadeIn tagName="div" threshold={.5}>
            <ResumePortrait id="portrait" portrait={props.values.portrait} />
          </FadeIn>
        </Container>



        {/* <!-- About --> */}
        <Container maxWidth="lg" className={paddingClass([10, null])}>
          <Columns>
            <Column span={{ xs: 12, md: 3 }} className={cn(marginClass([null, null, 4]))}>
              <Heading size={3} className="c--grover section-header">about —</Heading>
            </Column>
            <Column span={{ xs: 12, md: 9 }}>
              <div>
                <Heading size={5} className="">It's not just what you do that matters, but how you do it.</Heading>
                <p className={cn("t--lead", marginClass([5, null, 8]))}>Below is a collection of some of my views on how I approach design — or perhaps something greater. However cliché some may be, I think its a good way to sum up what to expect from me as a designer / human being.</p>
              </div>
              <FadeIn.Observer threshold={.25}>
                <div className="grid-thing -span-2">
                  {props.values.about.manifesto.map((value, index) => {
                    //  craft
                    if (value === 'craft') {
                      return (
                        <div className="grid-thing__item" key="craft">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-craft.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Craft</Heading>
                            <p>Every good idea deserves to be properly executed. I take pride in honing my technical abilities and executing designs the right way.</p>
                          </FadeIn.Child>
                        </div>
                      )
                    }

                    // care
                    if (value === 'care') {
                      return (
                        <div className="grid-thing__item" key="care">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-care.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Care</Heading>
                            <p>Care about what you do. If it's not worth doing right, it's not worth doing at all.</p>
                          </FadeIn.Child>
                        </div>
                      )
                    }

                    // purpose
                    if (value === 'purpose') {
                      return (
                        <div className="grid-thing__item" key="purpose">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-purpose.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Purpose</Heading>
                            <p>Everything should have one. When it comes to design, this is important. In life, probably even more so.</p>
                          </FadeIn.Child>
                        </div>
                      )
                    }

                    // beyond
                    if (value === 'beyond') {
                      return (
                        <div className="grid-thing__item" key="beyond">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-beyond.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Beyond</Heading>
                            <p>Normal is boring, make something better. I shoot to deliver above expectations.</p>
                          </FadeIn.Child>
                        </div>
                      )
                    }

                    // grow
                    if (value === 'grow') {
                      return (
                        <div className="grid-thing__item" key="grow">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-grow.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Grow</Heading>
                            <p>Seek opportunities to learn and grow. Continued education is important and something I enjoy.</p>
                          </FadeIn.Child>
                        </div>
                      )
                    }

                    // climb
                    if (value === 'climb') {
                      return (
                        <div className="grid-thing__item" key="climb">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-climb.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Climb</Heading>
                            <p>A simple challenge to myself — Make every project better than the last, in at least one way.</p>
                          </FadeIn.Child>
                        </div>
                      )
                    }

                    // balance
                    if (value === 'balance') {
                      return (
                        <div className="grid-thing__item" key="balance">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-balance.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Balance</Heading>
                            <p>A more generic concept, but I think finding balance is important in most aspects of life. </p>
                          </FadeIn.Child>
                        </div>
                      )
                    }

                    // enjoy
                    if (value === 'enjoy') {
                      return (
                        <div className="grid-thing__item" key="enjoy">
                          <FadeIn.Child tagName="div" delay={index * 250}>
                            <div className="t--center">
                              <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/manifesto-enjoy.png" className={marginClass([null, null, 3])} alt="" height="80px" />
                            </div>
                            <Heading size={3} margin={[null, null, 3]}>Enjoy</Heading>
                            <p>None of this matters much if you’re a boring jerk. </p>
                          </FadeIn.Child>
                        </div>
                      )
                    }
                  })}
                </div>
              </FadeIn.Observer>
            </Column>
          </Columns>
        </Container>

        {/* <!-- Past --> */}
        <Container maxWidth="lg" className={paddingClass([10, null])}>
          <Columns>
            <Column span={{ xs: 12, md: 3 }}>
              <Heading size={3} className="c--jazzy section-header">past —</Heading>
            </Column>
            <Column span={{ xs: 12, md: 9 }}>
              <ResumePast layout={props.values.experience.past} />
            </Column>
          </Columns>
        </Container>

        {/* <!-- Present --> */}
        <Container maxWidth="lg" className={paddingClass([10, null])}>
          <Columns>
            <Column span={{ xs: 12, md: 3 }}>
              <Heading size={3} className="c--lightning section-header">present —</Heading>
            </Column>
            <Column span={{ xs: 12, md: 9 }}>
              {props.values.experience.past === 'detail' &&
                <div className="job">
                  <div className={'f--1'}>
                    <span className="job__date">2018–Now</span>
                    <span className="job__location">Adair Homes</span>
                  </div>
                  <Heading size={5} className="job__title">UX Developer</Heading>
                  <p className="job__desc">This ambiguously titled position primarly includes UX design for our internal and external web marketing and applications along with front-end development.</p>
                </div>
              }
              {props.values.experience.past === 'nutshell' &&
                <div>
                  <h2>UX Developer at Adair Homes</h2>
                  <p className="t--lead">This ambiguously titled position primarly includes UX design for our internal and external web marketing and applications along with front-end development.</p>
                </div>
              }

              <Heading size={3} className={cn("")} margin={([6, null, 6])}>
                Notable Skills and Experience
              </Heading>
              <Columns className={cn(marginClass([null, null, 4]))}>
                {props.values.experience.skills.indexOf('print') > -1 &&
                  <Column span={{ xs: 12, sm: 6 }} className="skill-list">
                    <Card>
                      <Card.Block>
                        <Heading size={3} margin={[null, null, 3]}>ink —</Heading>
                        <ul className="-relaxed t--mono">
                          <li>Print Design &amp; Production</li>
                          <li>Environmental Graphics</li>
                          <li>Signage &amp; Wayfinding</li>
                          <li>Screen Printing</li>
                          <li>Letterpress Printing</li>
                        </ul>
                      </Card.Block>
                    </Card>
                  </Column>
                }
                {props.values.experience.skills.indexOf('digital') > -1 &&
                  <Column span={{ xs: 12, sm: 6 }} className="skill-list">
                    <Card>
                      <Card.Block>
                        <Heading size={3} margin={[null, null, 3]}>pixels —</Heading>
                        <ul className="-relaxed t--mono">
                          <li>Branding / Logos</li>
                          <li>Web / Digital Design</li>
                          <li>Iconography</li>
                          <li>Illustration</li>
                          <li>Photo Editing</li>
                        </ul>
                      </Card.Block>
                    </Card>
                  </Column>
                }
                {props.values.experience.skills.indexOf('development') > -1 &&
                  <Column span={{ xs: 12, sm: 6 }} className="skill-list">
                    <Card>
                      <Card.Block>
                        <Heading size={3} margin={[null, null, 3]}>bytes —</Heading>
                        <ul className="-relaxed t--mono">
                          <li>Javascript, SCSS, PHP</li>
                          <li>Node, Laravel, CraftCMS</li>
                          <li>Bootstrap, UIkit</li>
                          <li>Composer, NPM</li>
                          <li>Vagrant</li>
                        </ul>
                      </Card.Block>
                    </Card>
                  </Column>
                }

                <Column span={{ xs: 12, sm: 6 }} className="skill-list">
                  <Card>
                    <Card.Block>
                      <Heading size={3} margin={[null, null, 3]}>software —</Heading>
                      <ul className="-relaxed t--mono">
                        <li>Adobe inDesign / Photoshop / Illustrator</li>
                        <li>Sketch</li>
                        <li>Apple Keynote</li>
                        <li>Microsoft Office</li>
                        <li>Asana</li>
                      </ul>
                    </Card.Block>
                  </Card>
                </Column>

                {props.values.experience.skills.indexOf('other') > -1 &&
                  <Column span={{ xs: 12, sm: 6 }} className="skill-list">
                    <Card>
                      <Card.Block>
                        <Heading size={3} margin={[null, null, 3]}>etc —</Heading>
                        <ul className="-relaxed t--mono">
                          <li>Baked goods</li>
                          <li>Book binding</li>
                          <li>Unicycling</li>
                          <li>Spoon carving</li>
                          <li>Entry level Ukelele skills</li>
                        </ul>
                      </Card.Block>
                    </Card>
                  </Column>
                }
              </Columns>
            </Column>
          </Columns>
        </Container>

        {/* <!-- Future --> */}
        <Container maxWidth="lg" className={paddingClass([10, null])}>
          <Columns>
            <Column span={{ xs: 12, md: 3 }} className={cn(marginClass([4, null, null]))}>
              <Heading size={3} className="c--gummy section-header">future —</Heading>
            </Column>
            <Column span={{ xs: 12, md: 9 }} className={cn(marginClass([4, null, null]))}>
              <Heading size={2} family="mono" className="c--gray-light" margin={[null, null, 2]}>
                Open to Possibilities.
              </Heading>
              <Heading size={5} margin={[null, null, 5]}>Potentially open for interesting side projects, charitable help, or other opportunities</Heading>
              <p className={marginClass([3, null, null])}>If you have a fun project you need help with, get in touch. I'm always interested in collaborating with creative-minded businesses or individuals.</p>

              <Columns className={marginClass([8, null, null])}>
                <Column span={{ xs: 12, sm: 6 }} className={marginClass([null, null, 3])}>
                  <Heading size={2} margin={[null, null, 2]} className="c--gray-light">In the queue</Heading>
                  <Heading size={3} margin={[null, null, 3]}>Wanting to Learn —</Heading>
                  <ul className="-relaxed t--mono">
                    <li>After Effects / Motion Graphics</li>
                    <li>Web prototyping (Framer, or similar)</li>
                    <li>Font Design</li>
                    <li>App Development</li>
                    <li>Calligraphy / lettering</li>
                    {/* <!-- <li>Leathercraft</li>
                      <li>The Art of Bread making</li> --> */}
                  </ul>
                </Column>
                <Column span={{ xs: 12, sm: 6 }} className={marginClass([null, null, 3])}>
                  <Heading size={2} margin={[null, null, 2]} className="c--gray-light">Interests</Heading>
                  <Heading size={3} margin={[null, null, 3]}>Would Love to Work On —</Heading>
                  <ul className="-relaxed t--mono">
                    <li>Application design</li>
                    <li>Websites</li>
                    <li>Restaurant branding / signage</li>
                    <li>Menu design</li>
                    <li>Beer / Food Packaging</li>
                    <li>...Something new</li>
                  </ul>
                </Column>
              </Columns>

            </Column>
          </Columns>
        </Container>

        <section id="resume-outro" style={{ backgroundImage: "/img/resume-outro.jpg" }}>
          <Container maxWidth="md" className={paddingClass([10, null])}>
            <Columns className={cn('t--center')} align="middle">
              <Column>
                <FadeIn tagName="div" threshold={.2}>
                  <img src="https://adam-volkman-portfolio.s3-us-west-2.amazonaws.com/resume/resume-cta-trio.jpg" alt="" width="200" className={marginClass([null, null, 6])} />
                  <Heading size={4} margin={[null, null, 6]} className={cn('c--late-night')}>Think we'd work well together?</Heading>
                  <p>
                    <AnchorButton
                      href="mailto:adam.volkman@gmail.com?subject=I saw your resume and don't dislike you"
                      size="large"
                      intent="primary"
                      label="Get in touch"
                    />
                  </p>
                </FadeIn>
              </Column>
            </Columns>
          </Container>
        </section>

        <ResumeMenu />

        <div className={cn('bg--jazzy', paddingClass([11, null]))}>
          <Container maxWidth="lg" className="mb-6 mb-0--@md">
            <Columns>
              <Column className="t--center">
                <a className="heading f--5 c--white" href="/visual-summary">
                  Enough talk, show me some pictures. <Icon name="ArrowRightLg" />
                </a>
              </Column>
            </Columns>
          </Container>
        </div>
      </div>
    </motion.div>
  )
}
