import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { StoreState } from '../../../state/create-store'
import { resumeActions } from '../../../state/actions'
import { Icon } from '../../../components'

export const ResumeFormNav: React.FunctionComponent = () => {

  //
  // Constants
  //
  const dispatch = useDispatch()
  const furthestAllowed = useSelector((state: StoreState) => {
    return state.resume.status.furthestAllowed
  })
  const furthestVisited = useSelector((state: StoreState) => {
    return state.resume.status.furthestVisited
  })
  const currentStep = useSelector((state: StoreState) => {
    return state.resume.status.currentStep
  })
  const isComplete = useSelector((state: StoreState) => {
    return state.resume.status.isComplete
  })

  const disableNext = currentStep >= furthestAllowed
  const showPrompt = currentStep === furthestVisited && !disableNext
  const disablePrev = currentStep < 1

  //
  // Actions
  //

  function prevStep() {
    dispatch(resumeActions.prevStep())
  }

  function nextStep() {
    dispatch(resumeActions.nextStep())
  }

  function close() {
    dispatch(resumeActions.editMode({ isActive: false }))
  }


  //
  // Render
  //

  const promptText = 'Next'

  const variants = {
    active: {
      width: 200,
      height: 46,
      transition: { duration: .65, ease: [.71, .1, .39, .91] },
    },

    disabled: {
      width: 36,
      height: 36,
      transition: { duration: .35, ease: [.71, .1, .39, .91] },
    },

    default: {
      width: 36,
      height: 36,
      transition: { duration: .35, ease: [.71, .1, .39, .91] },
    }
  }

  const state = disableNext ? 'disabled' : showPrompt ? 'active' : 'default'

  return (
    <div className="editor__nav">
      <button
        key="prev-btn"
        className={cn('editor__back-btn', { disabled: disablePrev })}
        disabled={disablePrev}
        onClick={prevStep}
      >
        <span className="arrow-wrap">
          <Icon name="ArrowLeftXl" />
        </span>
      </button>

      <motion.div
        key="next-btn"
        className={cn(
          'editor__next-btn',
          {
            '-disabled': disableNext,
            '-active': showPrompt,
          }
        )}
        onClick={nextStep}
        variants={variants}
        animate={state}
      >
        <motion.span
          key="next-btn-text"
          className="editor-btn__text"
          variants={{
            active: {
              opacity: 1,
              transition: { delay: .4, duration: .25 },
            },
            default: { opacity: 0, y: 0 },
            disabled: { opacity: 0, y: 0 }
          }}
          animate={state}
        >
          {promptText}
        </motion.span>
        <div className="arrow-wrap">
          <Icon name="ArrowRightXl" />
        </div>
      </motion.div>

      {isComplete &&
        <motion.button
          key="done-btn"
          className="editor__next-btn -done"
          variants={variants}
          initial="default"
          animate="active"
          onClick={close}
        >
          <motion.span
            className="editor-btn__text"
            variants={{
              active: {
                opacity: 1,
                transition: { delay: .4, duration: .25 },
              },
              default: { opacity: 0, y: 0 },
            }}
          >Done</motion.span>
        </motion.button>
      }
    </div>
  )
}
