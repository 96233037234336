import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import { Column, Columns, Heading } from '../../../../components'
import { StoreState } from '../../../../state/create-store'
import { marginClass } from '../../../../library/spacing'

export const InterestsPreview: React.FC = () => {
  const dispatch = useDispatch()
  const interests = useSelector((state: StoreState) => {
    return state.resume.values.about.personal
  })

  function renderInterest(interest: string) {
    switch (interest) {
      case 'cooking':
        return <span>Creating dirty dishes</span>
      case 'hiking':
        return <span>Sleeping outside</span>
      case 'television':
        return <span>Watching Cartoons</span>
      case 'tennis':
        return <span>Pretending I'm Federer</span>
      case 'crafts':
        return <span>Making stuff for twice the price of just buying it</span>
      case 'learn':
        return <span>Google-ing everything</span>
    }
  }


  return (
    <div id="phase__interests">
      <Columns className={cn(marginClass([4, null, null]))}>
        <Column>
          {interests.length > 0 &&
            <ul className="-checklist">
              <li key="header">
                <Heading size={2} family="mono" className={cn('c--jazzy', marginClass([null, null, 2]))}>
                  Personal Interests include:
                </Heading>
              </li>
              {interests.map(interest => (
                <li
                  key={interest}
                  className={cn(
                    'heading',
                    'f--5',
                    'c--late-night',
                    '-checked',
                    marginClass([null, null, 4])
                  )}
                >
                  {renderInterest(interest)}
                </li>
              ))}
            </ul>
          }
          {interests.length === 0 &&
            <div className={cn('message', '-info', '-max--sm', '-center', marginClass([4, null]))}>
              <p className="t--sans c--jazzy">
                <strong>Pro Tip! —</strong> Look for items you are also interested
                  in so that it will make a personal connection.
                </p>
            </div>
          }
        </Column>
      </Columns>
    </div>
  )
}
