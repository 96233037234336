import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { min, find } from 'lodash-es'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'

import { StoreState } from '../../../state/create-store'
import { resumeActions } from '../../../state/actions'
import { schema, SchemaPhase, getStepOffset, schemaSteps } from '../schema'
import { ResumeFormNav } from './resume-form-nav'
import { ResumeFormStep } from './resume-form-step'
import { ResumeFormWelcome } from './resume-form-welcome'
import { ResumeFormTOC } from './resume-form-toc'

export const ResumeEditorForm: React.FunctionComponent = (props) => {

  //
  // Constants
  //

  const status = useSelector((state: StoreState) => {
    return state.resume.status
  })
  const direction = useSelector((state: StoreState) => {
    return state.resume.status.editorDirection
  })
  const dispatch = useDispatch()
  const totalPhases = schema.phases.length
  const percentComplete = ((status.completedSteps + 1) / status.totalSteps) * 100
  const furthestPhaseAllowed = min([status.completedPhases, totalPhases - 1])

  //
  // Actions
  //

  function setPhase(phase: number, step = 0): void {
    if (phase < 0 || phase > furthestPhaseAllowed || phase > totalPhases) return
    dispatch(resumeActions.setPhase({ phase, step: getStepOffset(phase) + step }))
  }

  function closePreview(): void {
    dispatch(resumeActions.showPreview(false))
  }

  const currentPhase: SchemaPhase = typeof status.currentPhase == 'string'
    ? find(schema.phases, ['id', status.currentPhase])
    : schema.phases[status.currentPhase]

  const stepProps = schemaSteps[status.currentStep]

  return (
    <motion.div
      className="resume-editor__form"
      onMouseDown={closePreview}
      initial={{ x: '-100%' }}
      animate={{ x: '0%' }}
      exit={{ x: '-100%' }}
      transition={{ damping: 300, stiffness: 150 }}
    >
      <div className="editor__wrap">
        {/* Header */}
        <div className="editor__header">
          <div className="progress editor__progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={percentComplete}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: percentComplete + "%" }}
            />
          </div>
          <h5 className="editor__phase-heading">
            {currentPhase
              ? currentPhase.title
              : ""
            }
          </h5>
          <ul className="editor__phase-dots">
            {schema.phases.map((phase, index) => (
              <li
                key={phase.id}
                className={cn({
                  "-active": status.currentPhase == index,
                  "-disabled": furthestPhaseAllowed < index,
                })}
                onClick={() => {
                  setPhase(index)
                }}
              >
                {index + 1}
              </li>
            ))}
          </ul>
          <hr />
        </div>

        <AnimatePresence custom={direction}>
          {status.currentPhase === 'toc' &&
            <ResumeFormTOC
              key="toc"
            />
          }

          {status.currentPhase === 'welcome' &&
            <ResumeFormWelcome
              key="welcome"
            />
          }

          {typeof status.currentPhase === 'number' &&
            <ResumeFormStep
              key={stepProps.id}
              {...stepProps}
              index={status.currentStep}
            />
          }
        </AnimatePresence>

        {/* <!-- Footer --> */}
        <div className="editor__footer">
          <ResumeFormNav />
        </div>
      </div>
    </motion.div>
  )
}
