import React from 'react'
import { difference } from 'lodash-es'

import { SchemaStep } from '../schema'
import { Column, Columns, Checkbox } from '../../../components'
import { marginClass } from '../../../library/spacing'

export interface CheckboxGroupProps {
  options: SchemaStep['options']
  value: string[]
  onUpdate?: (values: string[]) => void
}

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps> = (props) => {
  function selectAll() {
    const options = props.options.map(o => o.value)
    const newVals = difference(options, props.value)
    // add remaining values in order.
    if (typeof props.onUpdate == 'function') {
      props.onUpdate(props.value.concat(newVals))
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    const isChecked = props.value.indexOf(value) > -1
    let newValues = [...props.value]
    // either add or remove
    if (isChecked) {
      newValues = difference(props.value, [value])
    } else {
      newValues.push(value)
    }

    if (typeof props.onUpdate == 'function') {
      props.onUpdate(newValues)
    }
  }

  function isActive(option: { label: string; value: string }) {
    return props.value.indexOf(option.value) > -1
  }

  return (
    <Columns>
      {props.options.map(option => (
        <Column span={4} className={marginClass([null, null, 4])} key={option.value}>
          <Checkbox
            field={option.value}
            label={option.label}
            value={option.value}
            onChange={handleChange}
            isChecked={isActive(option)}
          />
        </Column>
      ))}
      <Column span={12}>
        <p className="t--small c--gray-light t--sans t--center mb-0">
          or just  <a onClick={selectAll} href="#">Select All</a>
        </p>
      </Column>
    </Columns>
  )
}
