import React from 'react'
import { useSelector } from 'react-redux'

import { StoreState } from '../../../../state/create-store'
import { BaseProps } from '../../../../types'
import { ResumePast } from '../../resume-past'

export const PastPreview: React.FunctionComponent<BaseProps> = (props) => {

  const layout = useSelector((store: StoreState) => {
    return store.resume.values.experience.past
  })

  return (
    <div id="phase__past" className={props.className}>
      <ResumePast layout={layout} />
    </div>
  )
}
