import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { S3ImageAssetConnection } from '../../types'
import { BreakpointValues } from '../../library'
import { StoreState } from '../../state/create-store'
import { resumeActions } from '../../state/actions'
import { paddingClass, marginClass } from '../../library/spacing'
import {
  Container,
  Toggler,
  Button,
  Columns,
  Column,
  Heading,
  Tooltip
} from '../../components'


interface QueryData {
  bgImageLg: S3ImageAssetConnection
  bgImageMd: S3ImageAssetConnection
  bgImageSm: S3ImageAssetConnection
}


export const ResumeIntro: React.FC = () => {
  const data = useStaticQuery<QueryData>(graphql`
    query ResumeIntroQuery{
      bgImageLg: allS3ImageAsset(filter: {Key: {eq: "resume/resume-intro-xl.jpg"}}) {
        nodes {
          childImageSharp {
            fixed(width: 1800) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
      bgImageMd: allS3ImageAsset(filter: {Key: {eq: "resume/resume-intro-md.jpg"}}) {
        nodes {
          childImageSharp {
            fixed(width: 1260) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
      bgImageSm: allS3ImageAsset(filter: {Key: {eq: "resume/resume-intro-sm.jpg"}}) {
        nodes {
          childImageSharp {
            fixed(width: 800) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  `)
  const dispatch = useDispatch()
  const status = useSelector((state: StoreState) => {
    return state.resume.status
  })

  function start() {
    // Begin the resume builder
    dispatch(resumeActions.editMode({ isActive: true }))
    if (status.isTourComplete) {
      dispatch(resumeActions.completeStep({ index: -1 }))
      dispatch(resumeActions.setPhase({ phase: 0, step: 0 }))
    }
  }

  return (
    <motion.div
      id="resume-intro"
      key="intro"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 1 } }}
    >
      <div className="resume-intro__text align-items--center">
        <div className="w--100" style={{ position: 'relative' }}>
          {/* {data.bgImageLg.nodes[0] &&
            <picture className="resume-intro__illo">
              <source media={`(min-width: ${BreakpointValues.xl}px)`} srcSet={data.bgImageLg.nodes[0].childImageSharp.fixed.srcSet} />
              <source media={`(min-width: ${BreakpointValues.md}px)`} srcSet={data.bgImageMd.nodes[0].childImageSharp.fixed.srcSet} />
              <source media={`(max-width: ${BreakpointValues.md}px)`} srcSet={data.bgImageSm.nodes[0].childImageSharp.fixed.srcSet} />
              <img
                srcSet={`
                  ${data.bgImageLg.nodes[0].childImageSharp.fixed.srcSet},
                  ${data.bgImageMd.nodes[0].childImageSharp.fixed.srcSet},
                  ${data.bgImageSm.nodes[0].childImageSharp.fixed.srcSet},
                `}
                src={`
                  ${data.bgImageMd.nodes[0].childImageSharp.fixed.src},
                `}
                sizes={`
                  (min-width: ${BreakpointValues.xl}px) 1800px,
                  (min-width: ${BreakpointValues.md}px) 1260px,
                  (max-width: ${BreakpointValues.md}px) 800px
                `}
              />
            </picture>
          } */}
          <Container>
            <Columns justify="center" align="middle">
              <Column span={{ sm: 10, md: 8 }} className={cn('t--center', paddingClass([null, null, 8]), 'max--md')}>
                <Heading size={3} margin={[null, null, 2]} className="c--gummy">Adam's build-a-resume®</Heading>
                <Heading size={6} margin={[null, null, 10]} className="c--late-night">Get the&nbsp;
                  <Toggler
                    options={['designer', 'developer', 'doodler', 'bed tester', 'ice cream taster', 'underwear model', 'underling']}
                  /> you’ve always wanted!
                  <Tooltip>
                    <sup className="c--jean-jacket t--normal">*</sup>
                    <span>*as long as its me, of course</span>
                  </Tooltip>
                </Heading>
                <p>
                  <Button onClick={start} intent="primary" size="large">Lets Go!</Button>
                </p>
                <p className={cn('f--2', marginClass([4, null, null]))}>
                  <Link className="c--gray-light" to="/resume/default">
                    or view the default
                  </Link>
                </p>
              </Column>
            </Columns>
          </Container>
        </div>
      </div>
    </motion.div>
  )
}