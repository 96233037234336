import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { findIndex } from 'lodash-es'

import { resumeActions } from '../../../state/actions'
import { ResumeFormStep } from './resume-form-step'
import { schema, SchemaStep } from '../schema'
import { Select, GenericOption } from '../../../components/form/select'
import { Button, Column, Columns, Buttons } from '../../../components'

const welcomeSchema: SchemaStep = {
  id: 'toc',
  phase: 'welcome',
  intro: 'Table of Contents',
  type: 'intro',
}

export const ResumeFormTOC: React.FC = () => {
  const dispatch = useDispatch()
  const options: GenericOption<number>[] = schema.phases.map((phase, index) => {
    return {
      label: phase.title,
      value: index,
    }
  })


  function phaseSelect(value: number) {
    let index = findIndex(options, ['value', value])
    if (index > -1) {
      dispatch(resumeActions.setPhase({ phase: index, step: 0 }))
    }
  }


  return (
    <ResumeFormStep key="toc" index={-1} {...welcomeSchema}>
      <Fragment>
        <div className="editor__input -condensed">
          <Select
            field="goto"
            options={[]}
            activeOptions={[]}
            renderLabel="label"
            renderOption="label"
            optionIdProp="value"
            placeholder="Go To..."
          />
        </div>

        <div className="editor__input -standard">
          <Columns >
            <Column span={12}>
              <Buttons>
                {options.map((option, index) => (
                  <Button
                    key={index}
                    intent="info"
                    size="large"
                    appearance="fill"
                    onClick={() => phaseSelect(option.value)}
                    isFullWidth
                  >
                    {option.label}
                  </Button>
                ))}
              </Buttons>
            </Column>
          </Columns>
        </div>
      </Fragment >
    </ResumeFormStep >
  )
}
